import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import Grid from "../../components/Grid";

const Lot = ({
  shippers,
  warehouses,
  productsAndLots,
  reduxGetClients,
  reduxGetWarehouses,
  reduxGetProductAndLots,
  resetProductAndLotsState, // Añadir esta línea
}) => {
  const [offset, setOffset] = useState(10);
  const [remitenteSelected, setRemitenteSelected] = useState(null);
  const [bodegaSelected, setBodegaSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const countryUser = userData.idCountry ? userData.idCountry : null;
  const countryUserStr = countryUser ? countryUser.toString() : null;

  useEffect(() => {
    reduxGetClients();
    reduxGetWarehouses({ page: 1, offset: 1000 });

    // Limpieza al desmontar el componente
    return () => {
      resetProductAndLotsState();
    };
  }, [reduxGetClients, reduxGetWarehouses, resetProductAndLotsState]);

  const handleSelectChange = (option, action) => {
    if (action.name === "remitente") {
      setRemitenteSelected(option);
    } else {
      setBodegaSelected(option);
    }
  };

  const validateRemitente = () => {
    if (!remitenteSelected) {
      Swal.fire({
        icon: "warning",
        title: "Remitente obligatorio",
        text: "Debe seleccionar un Remitente antes de proceder.",
        confirmButtonText: "OK",
      });
      return false;
    }
    return true;
  };

  const performSearch = () => {
    if (!validateRemitente()) return;

    const payload = {
      page: 1,
      offset,
      idCompany: remitenteSelected.value,
      ...(bodegaSelected?.value && { idWarehouse: bodegaSelected.value }),
      search: searchTerm,
    };

    reduxGetProductAndLots(payload);
  };

  const handleChangePage = (page) => {
    if (!validateRemitente()) return;

    const payload = {
      page,
      offset,
      idCompany: remitenteSelected.value,
      ...(bodegaSelected?.value && { idWarehouse: bodegaSelected.value }),
      search: searchTerm,
    };

    reduxGetProductAndLots(payload);
  };

  const handleChangeRange = (value) => {
    setOffset(value);

    if (!validateRemitente()) return;

    const payload = {
      page: 1,
      offset: value,
      idCompany: remitenteSelected.value,
      ...(bodegaSelected?.value && { idWarehouse: bodegaSelected.value }),
      search: searchTerm,
    };

    reduxGetProductAndLots(payload);
  };

  const handleChangeSearch = (search) => {
    setSearchTerm(search);
    if (!validateRemitente()) return;

    const payload = {
      page: 1,
      offset,
      idCompany: remitenteSelected.value,
      ...(bodegaSelected?.value && { idWarehouse: bodegaSelected.value }),
      search,
    };

    reduxGetProductAndLots(payload);
  };

  const shipperOptions = useMemo(() => {
    return shippers?.items
      ?.filter((e) => e.isActive && (!countryUserStr || e.country === countryUserStr))
      .map((shipper) => ({
        value: shipper.idCompany,
        label: shipper.description,
      })) || [];
  }, [shippers, countryUserStr]);

  const warehouseOptions = useMemo(() => {
    return warehouses?.items
      ?.filter(
        (w) =>
          w.isActive &&
          (!countryUserStr || w.warehouseHubs?.some((hub) => hub.hub.idCountry == countryUserStr))
      )
      .map((w) => ({
        value: w.idWarehouse,
        label: w.name || w.warehouseCode,
      })) || [];
  }, [warehouses, countryUserStr]);

  const mergedData = useMemo(() => {
    if (!productsAndLots?.items) return [];

    return productsAndLots.items.map((product) => ({
      idProduct: product.idProduct,
      sku: product.sku,
      name: product.name,
      abbreviation: product.abbreviation || "",
      warehouse: product.warehouse ?? "",
      company: product.company ?? "",
      idLot: product.idLot || null,
      lotNumber: product.lotNumber ? product.lotNumber.trim() : "-",
      serial: product.serial || "-",
      dateExp: product.expirationDate ? new Date(product.expirationDate).toLocaleDateString() : "-",
      quantity: product.quantity !== null ? product.quantity : "-",
      ubicacion: product.location || "-", // Nueva columna
      disponible: product.available !== null ? product.available : "-", // Nueva columna
    }));
  }, [productsAndLots]);

  const columns = [
    {
      title: "ID Producto",
      field: "idProduct",
      searchable: true,
      render: (rowData) => rowData.idProduct,
    },
    {
      title: "SKU",
      field: "sku",
      searchable: true,
      render: (rowData) => rowData.sku,
    },
    {
      title: "Nombre",
      field: "name",
      searchable: true,
      render: (rowData) => rowData.name,
    },
    {
      title: "# Lote",
      field: "idLot",
      searchable: true,
      render: (rowData) => rowData.idLot || "-",
    },
    {
      title: "Serie",
      field: "serial",
      searchable: true,
      render: (rowData) => rowData.serial || "-",
    },
    {
      title: "Fecha Expiración",
      field: "dateExp",
      render: (rowData) =>
        rowData.dateExp ? new Date(rowData.dateExp).toLocaleDateString() : "-",
    },
    // {
    //   title: "Cantidad",
    //   field: "quantity",
    //   render: (rowData) => (rowData.quantity !== null ? rowData.quantity : "-"),
    // },
    {
      title: "Ubicación",
      field: "ubicacion",
      render: (rowData) => rowData.ubicacion,
    },
    {
      title: "Disponible",
      field: "disponible",
      render: (rowData) => rowData.disponible,
    },
  ];

  const currentPage = productsAndLots?.page ? Number(productsAndLots.page) : 1;
  const totalPages = productsAndLots?.totalPages ? Number(productsAndLots.totalPages) : 0;
  const totalRecords = productsAndLots?.total || 0;

  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      maxHeight: "4.5vw",
      overflowY: "auto",
    }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }),
  };

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <Header title="Lotes" />
            <div className="card shadow mb-4">
              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    performSearch();
                  }}
                  className="d-flex align-items-center mb-3"
                >
                  <div className="mr-3" style={{ width: "250px" }}>
                    <Select
                      name="remitente"
                      placeholder="Seleccionar Remitente..."
                      isClearable
                      styles={customStyles}
                      options={shipperOptions}
                      value={remitenteSelected}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="mr-3" style={{ width: "250px" }}>
                    <Select
                      name="bodega"
                      placeholder="Seleccionar Bodega..."
                      isClearable
                      styles={customStyles}
                      options={warehouseOptions}
                      value={bodegaSelected}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <Button variant="primary" type="submit">
                    Buscar
                  </Button>
                </form>
                {productsAndLots.loading ? (
                  <div>Cargando...</div>
                ) : productsAndLots.error ? (
                  <div>Error: {productsAndLots.error}</div>
                ) : (
                  <Grid
                    cols={columns}
                    data={mergedData}
                    page={currentPage}
                    pages={totalPages}
                    total={totalRecords}
                    offset={offset}
                    onChangeSearch={handleChangeSearch}
                    onChangePage={handleChangePage}
                    onChangeRange={handleChangeRange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Conexión con Redux
const mapStateToProps = (state) => ({
  shippers: state.companyState.clients,
  warehouses: state.warehouseState.warehouses,
  productsAndLots: state.lotState.productsAndLots || { items: [], loading: false, error: null },
});

const mapDispatchToProps = (dispatch) => ({
  reduxGetClients: () => dispatch({ type: "FETCH_COMPANIESBYSHIPPER_REQUEST" }),
  reduxGetWarehouses: (payload) => dispatch({ type: "FETCH_WAREHOUSES_REQUEST", value: payload }),
  reduxGetProductAndLots: (payload) => dispatch({ type: "FETCH_PRODUCT_AND_LOTS", value: payload }),
  resetProductAndLotsState: () => dispatch({ type: "RESET" }), // Añadir esta línea
});

export default connect(mapStateToProps, mapDispatchToProps)(Lot);
