const initialState = {
  requestingFetchLots: false,
  successfulFetchLots: false,
  errorFetchLots: false,
  lots: {},
  requestingCreateLot: false,
  successfulCreateLot: false,
  errorsCreateLot: false,
  lot: null,
  requestingReadLot: false,
  successfulReadLot: false,
  errorsReadLot: false,
  rowEdited: null,
  requestingDeleteLot: false,
  successfulDeleteLot: false,
  errorsDeleteLot: false,
  rowDeleted: null,
  requestingUpdateLot: false,
  successfulUpdateLot: false,
  errorsUpdateLot: false,
  rowUpdated: null,
  requestingDeleteSoftLot: false,
  successfulDeleteSoftLot: false,
  errorsDeleteSoftLot: false,
  rowDeletedSoft: null,
};

const lotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_LOTS_REQUESTING":
      return {
        ...state,
        requestingFetchLots: true,
        successfulFetchLots: false,
        errorsFetchLots: false,
      };
    case "FETCH_LOT_SUCCESS":
      return {
        ...state,
        errorFetchLots: false,
        requestingFetchLots: false,
        successfulFetchLots: true,
        lots: action.value,
      };
    case "FETCH_LOT_ERROR":
      return {
        ...state,
        errorFetchLots: true,
        requestingFetchLots: false,
        successfulFetchLots: false,
      };
    case "CREATE_LOT_REQUESTING":
      return {
        ...state,
        requestingCreateLot: true,
        successfulCreateLot: false,
        errorsCreateLot: false,
      };
    case "CREATE_LOT_SUCCESS":
      return {
        ...state,
        errorsCreateLot: false,
        requestingCreateLot: false,
        successfulCreateLot: true,
        lot: action.value,
      };
    case "CREATE_LOT_ERROR":
      return {
        ...state,
        errorsCreateLot: true,
        requestingCreateLot: false,
        successfulCreateLot: false,
      };
    case "READ_LOT_REQUESTING":
      return {
        ...state,
        requestingReadLot: true,
        successfulReadLot: false,
        errorsReadLot: false,
      };
    case "READ_LOT_SUCCESS":
      return {
        ...state,
        errorsReadLot: false,
        requestingReadLot: false,
        successfulReadLot: true,
        rowEdited: action.value,
      };
    case "READ_LOT_ERROR":
      return {
        ...state,
        errorsReadLot: true,
        requestingReadLot: false,
        successfulReadLot: false,
      };
    case "DELETE_LOT_REQUESTING":
      return {
        ...state,
        requestingDeleteLot: true,
        successfulDeleteLot: false,
        errorsDeleteLot: false,
      };
    case "DELETE_LOT_SUCCESS":
      return {
        ...state,
        errorsDeleteLot: false,
        requestingDeleteLot: false,
        successfulDeleteLot: true,
        rowDeleted: action.value,
      };
    case "DELETE_LOT_ERROR":
      return {
        ...state,
        errorsDeleteLot: true,
        requestingDeleteLot: false,
        successfulDeleteLot: false,
      };
    case "UPDATE_LOT_REQUESTING":
      return {
        ...state,
        requestingUpdateLot: true,
        successfulUpdateLot: false,
        errorsUpdateLot: false,
      };
    case "UPDATE_LOT_SUCCESS":
      return {
        ...state,
        errorsUpdateLot: false,
        requestingUpdateLot: false,
        successfulUpdateLot: true,
        rowUpdated: action.value,
      };
    case "UPDATE_LOT_ERROR":
      return {
        ...state,
        errorsUpdateLot: true,
        requestingUpdateLot: false,
        successfulUpdateLot: false,
      };
    case "DELETE_SOFT_LOT_REQUESTING":
      return {
        ...state,
        requestingDeleteSoftLot: true,
        successfulDeleteSoftLot: false,
        errorsDeleteSoftLot: false,
      };
    case "DELETE_SOFT_LOT_SUCCESS":
      return {
        ...state,
        errorsDeleteSoftLot: false,
        requestingDeleteSoftLot: false,
        successfulDeleteSoftLot: true,
        rowDeletedSoft: action.value,
      };
    case "DELETE_SOFT_LOT_ERROR":
      return {
        ...state,
        errorsDeleteSoftLot: true,
        requestingDeleteSoftLot: false,
        successfulDeleteSoftLot: false,
      };
    case "RESET_LOT_FORM":
      return {
        ...state,
        requestingCreateLot: false,
        successfulCreateLot: false,
        errorsCreateLot: false,
        lot: null,
        requestingReadLot: false,
        successfulReadLot: false,
        errorsReadLot: false,
        rowEdited: null,
        requestingDeleteLot: false,
        successfulDeleteLot: false,
        rowDeleted: null,
        requestingUpdateLot: false,
        successfulUpdateLot: false,
        errorsUpdateLot: false,
        rowUpdated: null,
        requestingDeleteSoftLot: false,
        successfulDeleteSoftLot: false,
        errorsDeleteSoftLot: false,
        rowDeletedSoft: null,
      };
      
          case "FETCH_PRODUCT_AND_LOTS_REQUESTING":
            return {
              ...state,
              loading: true,
              error: false,
            };
      
          case "FETCH_PRODUCT_AND_LOTS_SUCCESS":
            return {
              ...state,
              loading: false,
              productsAndLots: {
                items: action.value.items,
                total: action.value.total,
                page: action.value.page,
                totalPages: action.value.totalPages,
              },
            };
      
          case "FETCH_PRODUCT_AND_LOTS_ERROR":
            return {
              ...state,
              loading: false,
              error: action.error || true, // Almacena el mensaje de error si está disponible
            };

    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default lotReducer;
